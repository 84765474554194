import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import moment from 'moment'

import TimeSlotPicker from '../TimeSlotPicker'

import Backend from '../../../../utils/Backend'

export default class SelectCollectModal extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
      isLoading: true,
      times: [],
      selectedDate: []
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      ...nextProps,
      confirmDate: null
    })
  }

  componentDidMount(){
    this._load()
  }

  _load(){

    Backend.getOpenSlots()
    .then(openSlots => {
      let times = []

      openSlots.forEach(slot => {
        let openTimes = slot.open_slots.map(time => {
          let date = moment(slot.date+" "+time)
          let openTime = moment(time, "HH:mm:ss")
          let closeTime = moment(time, "HH:mm:ss").add(15, "minute")
          times.push({
            date: date,
            opens: openTime.format("HH:mm a"),
            closes: closeTime.format("HH:mm a")
          })
        })
      })

      this.setState({ times, isLoading: false })
    })
  }

  _renderConfirmButtons(){
    let {
      confirmDate
    } = this.state

    if(!confirmDate){
      return null
    }

    return (
      <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => this.setState({ confirmDate: null })}>Cancel</button>

        <button
          type="button"
          className="btn btn-primary"
          onClick={() => this.props.onDateSelected(confirmDate)}>Self Collect</button>
      </>
    )
  }

  _renderTimeSlotPicker(){
    let {
      times,
      confirmDate,
      isLoading
    } = this.state

    if(isLoading){
      return (
        <div style={{
          textAlign: 'center',
          paddingTop: 120,
          paddingBottom: 120
        }}>
          <Spinner animation="border" />
        </div>
      )
    }

    if(confirmDate){

      let day = confirmDate.format("dddd, MMMM Do YYYY")

      let opens = confirmDate.format("HH:mm a")
      let closes = confirmDate.add(15, "minute").format("HH:mm a")

      let timeRange = opens + " and " + closes
      let subtitle = "Between " + timeRange + " on " + day

      return (
        <>
          <h5>Self Collect</h5>
          <p>{ subtitle }</p>
        </>
      )
    }

    return (
      <TimeSlotPicker
        items={times}
        onDateSelected={date => this.setState({ confirmDate: new moment(date) })}/>
    )
  }

  render() {
    let { show } = this.state
    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="kt-portlet kt-portlet--mobile">
            { this._renderTimeSlotPicker() }
    			</div>
        </Modal.Body>
        <Modal.Footer>
            { this._renderConfirmButtons() }
        </Modal.Footer>
      </Modal>
    )
  }
}
