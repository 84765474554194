import React from "react"

import SelectOrdersTable from './components/tables/SelectOrdersTable'

import SuccessModal from './components/modals/SuccessModal'
import SelfCollectModal from './components/modals/SelfCollectModal'
import AddServicesModal from './components/modals/AddServicesModal'

import SearchBar from './components/SearchBar'

import Backend from '../../utils/Backend'

export default class SelfCollect extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      searchTerm: '',
      isLoading: false,
      selectedOrders: [],
      showSelfCollectModal: false,
    }

    this.successModal      = React.createRef();
    this.selectOrdersTable = React.createRef();
  }

  _onSelfCollectPressed(){
    this.setState({ showSelfCollectModal: true })
  }

  _selfCollect(date){
    let { selectedOrders } = this.state

    this.setState({ showSelfCollectModal: false })
    Backend.selfCollect(selectedOrders, date.toISOString())
    .then((response) => {
      if(response.error){
        alert(response.error_description || "An unexpected error occurred.")
        this.setState({ showSelfCollectModal: true })
        return
      }
      this.setState({
        isLoading: false,
        selectedOrders: [],
      }, () => {
        this.successModal.current.show("Success", "")
        this.selectOrdersTable.current.refresh()
      })
    })
    .catch(error => {
      alert("Ooops", error.message)
    })
  }

  _renderSelfCollectButton(){
    let { selectedOrders } = this.state
    if(selectedOrders.length == 0){
      return null
    }

    return (
      <div className="kt-form kt-form--label-align-right kt-margin-t-20">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="kt-form__group kt-form__group--inline">
              <div className="kt-form__label kt-form__label-no-wrap">
                <label className="kt-font-bold kt-font-danger-">Selected
                  <span id="kt_datatable_selected_number"> { selectedOrders.length }</span> records:</label>
              </div>
              <div className="kt-form__control">
                <div className="btn-toolbar">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-brand btn-sm"
                      onClick={() => this._onSelfCollectPressed()}
                    >
                      Self COllect
                    </button>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="kt-portlet kt-portlet--mobile">
              <div className="kt-portlet__head kt-portlet__head--lg">
                <div className="kt-portlet__head-label">
                  <span className="kt-portlet__head-icon">
                    <i className="kt-font-brand flaticon-truck"></i>
                  </span>
                  <h3 className="kt-portlet__head-title">
                    Choose which vehicles to schedule for collection
                  </h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <SearchBar
                   onSearchTermChanged={searchTerm => this.setState({ searchTerm })}
                   showSelectStatusFilters={false}/>
                  {this._renderSelfCollectButton() }
                </div>
                <div className="kt-portlet__body kt-portlet__body--fit">

                {/* begin: Datatable  */}
                <SelectOrdersTable
                  ref={this.selectOrdersTable}
                  endpoint={window.env.REACT_APP_API_FEED+"?status=AVAILABLE_FOR_CALL_OFF"}
                  disabled={this.state.isLoading}
                  searchTerm={this.state.searchTerm}
                  onSelectedOrdersUpdated={selectedOrders => this.setState({ selectedOrders })}/>
                {/* end: Datatable  */}

                <SelfCollectModal
                  show={this.state.showSelfCollectModal}
                  onHide={() => this.setState({ showSelfCollectModal: false })}
                  onDateSelected={date => this._selfCollect(date)}
                  />

                <SuccessModal ref={this.successModal}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
